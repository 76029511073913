

body {
  margin: 0;
}

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

@font-face {
  font-family: 'Graphik-Regular';
  src: url(./assets/fonts/Graphik-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Graphik-Bold';
  src: url(./assets/fonts/Graphik-Bold.ttf) format('truetype');
}

