

.App {
  height: 100vh;
  width: 100vw;
  font-family: 'Graphik-Regular';
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.trans-box {
  width: 100%;
}

.row-padding {
  /* padding: 0 5%; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.header-row {
  /* background-color: rebeccapurple; */
  width: 100vw;
  /* height: 20vh; */
  object-fit: fill;
  /* background-color: #282c34; */
}

.box {
  border: 2px solid rgba(229, 236, 237, 1);
  border-radius: 10px;
  align-self: center;
  margin-bottom: 5%;
}

.row {
  /* padding: 50px; */
  /* background-color: rgb(211, 218, 216); */
  width: 90%;
  align-self: center;
}

.row-content {
  padding: 20px;
  display: flex;
  /* flex-grow: 1; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  /* width: 40%;; */
  font-family: 'Graphik-Regular';
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

}

.two-row {
  /* background-color: #f2f2f2; */
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.left-col {
  width: 40%;
  flex-grow: 2;
}

.right-col {
  background-color: rebeccapurple;
  width: 60%;
  /* flex-grow: 2; */

}

.row-padding {
  /* padding: 0; */
}

.gray-bg {
  background-color: #f2f2f2;
}

.dark-gray-bg {
  background-color: #d6d6d6;
}

.sub-heading {
  font-family: 'Graphik-Regular';
  color: #9f00fb;
  font-size: 18px;
  align-self: flex-start;
}

.footer-row {
  justify-self: flex-end;
  max-width: 100%;
  width: 100%;
  background-image: url('../assets/navbar/Rectangle 12.png');
  /* background-color: #9f00fb; */
  background-size: cover;
  background-position: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-row-acn {
  padding: 0 4%;
  width: 20%;
}

.footer-row-img {
  /* padding: 1% 0 0 0; */
  width: 50%;
}

.navbar-bg-img {
  height: 100%;
}

.title {
  color: #9f00fb;
  font-size: x-large;
  font-family: 'Graphik-Bold';
  font-weight: 200px;
  align-self: flex-start;
  padding: 5%;
}

.qr-subtitle {
  font-size: 20px;
  font-family: 'Graphik-Bold';
  font-weight: 600;
  color: #9f00fb;
  padding: 5% 0 0 0;

}

.app-download {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  margin: 5% 0;
  gap: 5px;
  a {
    /* width: 50%; */
  }
}

.app-icon {
  width: 100%;;
}

.table-d {
  font-family: 'Graphik-Bold';
  white-space: nowrap;
  float: inline-start;
}

.qr-switch {
  color: #9f00fb;
  /* background-color: #ffffff; */

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* width: 20%; */
}

.qr-box {
}

.qr-left {
  /* width: 80%; */
  text-align: left;
  margin: 2% 0;
  /* align-self: flex-start; */
}

.qr-row {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  white-space: pre-wrap;
}

.subtitle {
  /* color: #7803bc; */
  color: #9f00fb;
  /* margin-left: 5%; */
  font-size: large;
  font-family: 'Graphik-Bold';
  font-weight: 600;
  align-self: flex-start;
  /* align-self: center; */
  /* padding: 5% 0 0 5%; */
}

.trans-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.trans-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 100%;
  gap: 20px;
  /* background-color: #2196F3; */
}

.health-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.see-soon {
  background-image: url('../assets/bg1.svg');
  background-size: cover;
  /* width: 100%; */
  padding: 5%;
  /* background: radial-gradient(2451.47% 59891.07% at 139.72% 76.88%, #68C4D1 0.5%, #6D00B4 49.5%, #290C57 87.5%) warning: gradient uses a rotation that is not supported by CSS and may not behave as expected; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 48px;

  a {
    color: white;
    text-decoration: none;
  }

}

.forecast {
  text-decoration: underline;
  line-height: 20px;
}

.see-soon-weather {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-self: center;
  font-size: medium;
  img {
    width: 10%;
  }
}

.see-soon-title {
  font-family: 'Graphik-Bold';
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;

}

.see-soon-subtitle {
  font-family: 'Graphik-Regular';
  font-size: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.qr-box {
  display: flex;
  width: 100%;
  justify-self: flex-start;
  align-items: center;
  justify-content: center;
  background: rgba(229, 236, 237, 1);

  a {
    font-family: 'Graphik-Bold';
  }
}

.bottom-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;

}

.navbar-bg {
  background-image: url('../assets/navbar/Rectangle-mobile.svg');
  /* background-color: #9f00fb; */
  background-size: cover;
  background-position: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1% 5% 0 0;

}

.navbar-left {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 50%;
  height: 100%;

}

.navbar-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* background-color: #b1b7bc; */
  width: 50%;
}

.see-you {
  width: 40vw;
  object-fit: scale-down;
}

.see-date {
  width: 50vw;
  object-fit: scale-down;
  margin-top: -30px;
}

.see-vegas-span {
  width: 90%;
  display: flex;
  align-items: center;
}

.see-in-vegas {
  width: 80%;
}

.see-vegas {
  width: 30%;
  object-fit: scale-down;
}

.vector {
  height: 30%;
  justify-self: flex-end;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9f00fb;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #9f00fb;
}

input:focus + .slider {
  box-shadow: 0 0 1px #9f00fb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.trans-header {
  color: #9f00fb;
}

.trans-wrapper {
  width: 100%;
}

.second-box {
  display: flex;
  flex-direction: column;
}

.see-soon-inner {
  padding: 2% 0;

}


@media only screen and (min-width: 768px) {
  .see-soon-inner {
    width: 600px;
    font-size: 40px;

  }

  .qr-switch {
    background-color: #ffffff;
    padding: 2%;
    width: 300px;
    margin-bottom: 2%;
    font-size: 15px;
    font-family: 'Graphik-Bold';
    img {
      width: 80%;
      border: 2px solid #E5ECED
    }
  }

  .qr-span {
    display: flex;
    gap: 10px;
  }

  .qr-row {
    flex-wrap: wrap;
  }

  .title {
    font-size: 30px;
    padding: 2%;
  }

  .subtitle {
    font-size: 20px;
  }
}


@media only screen and (min-width: 1200px) {
  .see-soon-inner {
    width: 700px;
    font-size: 40px;

  }

  .trans-wrapper {
    width: 45%;
  }

  .second-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .navbar-bg {
    background-size: cover;
    padding: 1%;
    height: 10vh;
    width: 98%;
  }

  .trans-container {
    /* width: 25%; */
    width: 30%;
  }

  .see-vegas-span {
    width: 60%;
    display: flex;
    align-items: center;
  }

  .see-in-vegas {
    width: 50%;
  }

  .see-vegas {
    width: 20%;
  }


  .see-soon-title {
    font-size: 70px;
    line-height: 90px;
  }

  .title {
    padding: 2%;
    font-size: 30px;
  }

  .trans-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    /* gap: 10px; */
    height: auto;
    /* margin-bottom: 5%; */
  }

  .trans-header {
    margin: 0;
  }

  .trans-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 25%; */
    height: 100%;
    padding: 1%;
  }

  .box {
    align-self: flex-start;
  }

  .health-row {
  }

  .health-box {
    width: 95%;
    align-self: center;
    /* margin-left: 5%; */
    font-size: 80px;
    line-height: 40px;
    /* padd ing: 2%; */
    font-size: large;

    .row-content {
      align-items: start;
    }
  }

  .bottom-row {
    flex-direction: row;
    padding: 1%;
  }

  .row-content {
    font-size: 20px;
    line-height: 35px;
  }

  .qr-box {
    /* align-self: flex-end; */
    margin-right: 5%;

  }

  .subtitle, .qr-subtitle{
    font-size: 25px;
  }

  .see-soon-weather {

    justify-content: center;
    gap: 20px;
    img {
      width: 5%;
    }
  }

  .qr-row {
    flex-direction: row;
  }

  .qr-switch {
    background-color: #ffffff;
    padding: 2%;
    margin-bottom: 2%;
    font-size: 15px;
    font-family: 'Graphik-Bold';
    img {
      width: 80%;
      border: 2px solid #E5ECED
    }
  }


  .qr-span {
    display: flex;
    gap: 10px;
    white-space: nowrap;

    
  }

  .footer-row {
    height: 10vh;
  }

  .footer-row-img {
    height: 100%;
    width: auto;
  }

  .footer-row-acn {
    height: 40%;
    width: auto;
  } 


  .box {
    margin-bottom: 0;
  }

  .see-you {
    width: 30%;
  }



  .qr-switch {
    width: 300px;
  }

  .row {
    /* padding: 50px; */
    /* background-color: rgb(211, 218, 216); */
    width: 100%;
  }
}