.video-carousel-container {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    height: auto;
    /* position: relative; */
}

/* .mute-unmute-button {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
    border: none;
    cursor: pointer;
    opacity: .8;
} */

.video-carousel-object {
    /* height: 60%; */
}

.video-carousel-menu {
    display: flex;
    gap: 10px;
    width: 95%;
    overflow-x: scroll;
    padding: 2%;
}

.video-thumbnail {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    object-fit: cover
}

.video-thumbnail-img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit:contain
}

.video-carousel-video {
    width: 100%;
    height: 30vh;
    background-color: black;
}

.play-pause {
    position: absolute;
    width: 15%;
    /* top: 15%;
    left: 30%; */
}

/* desktop */
@media only screen and (min-width: 768px) {
    .video-carousel-video {
        height: 50vh;
    }

    .video-carousel-menu {

    }
}